var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CButton",
            { attrs: { color: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("Start")]
          ),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.seedProgressText) + " "),
          _c("br"),
          _c(
            "CProgress",
            { staticClass: "mt-10" },
            [_c("CProgressBar", { attrs: { value: _vm.percentage } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }